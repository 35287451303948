<template>
    <v-container
            id="regular-tables"
            fluid
            tag="section"
    >
        <v-row>
            <v-btn class="ma-2" color="success" @click="modalSimpan(item)">
                <v-icon left>mdi-plus</v-icon> Tambah User
            </v-btn>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                lg="12"
            >
                <base-material-card
                    class="px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Setting - User
                        </div>

                        <div class="subtitle-1 font-weight-light">
                        </div>
                    </template>
                    <template>
                        <v-card>
                            <v-card-title>
                                
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    label="Search"
                                    single-line
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :search="search"
                            >
                                <template v-slot:item.action="{ item }">
                                    <v-icon class="mx-1" @click="edit(item)">
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon class="mx-1" @click="hapus(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                        
                            </v-data-table>
                        </v-card>
                    </template>
                    <template>
                        <v-row justify="center">
                            <v-dialog v-model="dialogDelete" persistent max-width="320">
                                <v-card>
                                    <v-card-title class="headline">Hapus User</v-card-title>
                                    <v-card-text>
                                        <input type="hidden" name="id" id="id" v-model="id"/>
                                        <input type="hidden" name="is_delete" id="is_delete" v-model="is_delete"/>
                                        Apa anda yakin ingin menghapus data ini?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="green darken-1" text @click="dialogDelete = false">Tidak</v-btn>
                                        <v-btn color="green darken-1" text @click="simpan()">Ya</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </template>
                    <template>
                        <v-row justify="center">
                            <v-dialog
                            v-model="dialogSimpan"
                            persistent
                            max-width="600px"
                            transition="dialog-bottom-transition"
                            >
                            <v-card>
                                <v-card-title>
                                <span class="headline">Form Tambah/Ubah User</span>
                                </v-card-title>
                                <v-card-text>
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col cols="12">
                                                <input type="hidden" name="id" id="id" v-model="id"/>
                                                <v-text-field
                                                class="purple-input"
                                                label="Name"
                                                v-model="name"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                class="purple-input"
                                                label="Username"
                                                v-model="username"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                class="purple-input"
                                                label="Email"
                                                v-model="email"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select
                                                v-model="sex"
                                                :items="itemjk"
                                                label="Jenis Kelamin"
                                                data-vv-name="select"
                                                :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select
                                                v-model="type"
                                                :items="itemrole"
                                                label="Role User"
                                                data-vv-name="select"
                                                :rules="[rules.required]"
                                                :disabled="disableSelect"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" v-if="type==='Customer'">
                                                <v-text-field
                                                class="purple-input"
                                                label="Phone"
                                                v-model="phone"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" v-if="type==='Customer'">
                                                <v-text-field
                                                class="purple-input"
                                                label="Address"
                                                v-model="address"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                class="purple-input"
                                                label="Password"
                                                v-model="password"
                                                :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                                </v-card-text>
                                <v-card-actions>
                                <v-row>
                                    <v-col md="6" class="text-right">
                                        <v-btn
                                                color="warning"
                                                class="mr-0"
                                                @click="batal"
                                        >
                                            Batal
                                        </v-btn>
                                    </v-col>
                                    <v-col md="6" class="text-left">
                                        <v-btn
                                                color="success"
                                                class="mr-0"
                                                @click="simpan"
                                        >
                                            Simpan
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-row>
                        </template>
                </base-material-card>
                <v-snackbar
                    v-model="snackbar"
                    :color="color"
                    :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                        dark
                        icon
                        @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <div class="py-3"/>


    </v-container>
</template>
<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'

    export default {
        name: 'IndexUsers',
        data() {
            return {
                snackbar: false,
                color: 'success',
                message: '',
                dialog: false,
                dialogSimpan: false,
                dialogDelete: false,
                name: '',
                username: '',
                email: '',
                phone: '',
                address: '',
                type: null,
                itemrole: [
                    'Customer',
                    'Shopkeeper',
                ],
                sex: null,
                itemjk: [
                    'L',
                    'P',
                ],
                password: '',
                is_delete: '',
                id: '',
                search: '',
                rules: {
                    required: value => !!value || 'Required.',
                },
                headers: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Name',
                        filterable: true,
                        value: 'name',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Username',
                        filterable: true,
                        value: 'username',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Email',
                        filterable: true,
                        value: 'email',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Phone',
                        filterable: true,
                        value: 'phone',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Address',
                        filterable: true,
                        value: 'address',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Type',
                        filterable: true,
                        value: 'type',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Sex',
                        filterable: true,
                        value: 'sex',
                        width: 50,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                items: [],
            }
        },
        computed: {
            ...mapState(['currentUser']),
            showdiv() {
                return this.type === 'Customer';
            },
            disableSelect() {
                return this.id != '';
            }
        },
        mounted: function () {
            this.ambilData()
        },
        methods: {
            edit: function (item) {
                this.dialogSimpan = true
                this.id = item.id
                this.name = item.name
                this.username = item.username
                this.email = item.email
                this.phone = item.phone
                this.address = item.address
                this.type = item.type
                this.sex = item.sex
                this.is_delete = ''
            },
            hapus: function (item){
                this.id = item.id
                this.is_delete = '1'
                this.dialogDelete = true
            },
            batal: function () {
                this.dialogSimpan = false
                this.id = ''
                this.name = ''
                this.username = ''
                this.email = ''
                this.phone = ''
                this.address = ''
                this.type = null
                this.sex = null,
                this.password = ''
                this.is_delete = ''
            },
            modalSimpan: function () {
                this.id = ''
                this.name = ''
                this.username = ''
                this.email = ''
                this.phone = ''
                this.address = ''
                this.type = null
                this.sex = null,
                this.password = ''
                this.is_delete = ''
                this.dialogSimpan = true
            },
            simpan: function () {
                if (this.name && this.name !== '' && this.username && this.username !== '' && this.email && this.email !== '' 
                && this.sex && this.sex !== '' && this.type && this.type !== '') {
                    let data = {
                        name: this.name,
                        username: this.username,
                        email: this.email,
                        phone: this.phone,
                        password: this.password,
                        address: this.address,
                        type: this.type,
                        sex: this.sex,
                        is_delete: this.is_delete,
                        id: this.id,
                    }
                    console.log(data);
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    axios
                    ({method: 'post', url: ApiUrl + 'users/simpan', data: data, headers: header})
                        .then(response => {
                            if (response.data.success == 'passnull') {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Seluruh data harus diisi'
                            }else{
                                this.dialogSimpan = false;
                                if (response.data.success) {
                                    this.snackbar = true
                                    this.color = 'success'
                                    this.message = 'Berhasil simpan'
                                    this.name = ''
                                    this.username = ''
                                    this.email = ''
                                    this.phone = ''
                                    this.password = ''
                                    this.address = ''
                                    this.type = null
                                    this.sex = null
                                    this.id = ''
                                    this.$refs.form.resetValidation()
                                    this.ambilData()
                                } else {
                                    this.snackbar = true
                                    this.color = 'error'
                                    this.message = 'Gagal simpan'
                                }
                            }
                        })
                        .catch(error => {
                            this.dialogSimpan = false;
                            console.log(error)
                        })
                } else if(this.is_delete && this.is_delete !== ''){
                    let data = {
                        is_delete: this.is_delete,
                        id: this.id,
                    }
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    axios
                    ({method: 'post', url: ApiUrl + 'users/simpan', data: data, headers: header})
                        .then(response => {
                            this.dialogDelete = false;
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil hapus'
                                this.is_delete = ''
                                this.id = ''
                                //this.$refs.form.resetValidation()
                                this.ambilData()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal hapus'
                            }
                        })
                        .catch(error => {
                            this.dialogDelete = false;
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Seluruh data harus diisi'
                }
            },
            ambilData: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'users', headers: header})
                    .then(response => {
                        console.log(response)
                        if (response.data.success) {
                            this.items = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            }
        }
    }
</script>